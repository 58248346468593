import React, { useState } from "react"
import { navigate } from 'gatsby'
import Link from 'gatsby-link'
import ReactMarkdown from 'react-markdown'
import { FaEthereum } from 'react-icons/fa';
import { RiAliensLine, RiTempColdLine, RiEarthFill } from 'react-icons/ri';
import { OutboundLink } from "gatsby-plugin-google-gtag"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PlanetTags from "../components/planet-tags"
import icon from "../images/icon.png"

const NavLink = props => {
  if (!props.test) {
    return <Link to={props.url} className={`${props.className} button is-primary`}>{props.text}</Link>
  } else {
    return <span className={`${props.className} button`} disabled>{props.text}</span>
  }
}

const Pager = props => {
  const previousUrl = props.index - 1 === 1 ? '' : (props.index - 1).toString()
  const nextUrl = (props.index + 1).toString()
  const buttonClass = "button is-outlined is-primary button is-rounded is-responsive-small"

  return (
    <nav className={`pagination is-centered ${props.className}`} role="navigation">
      <NavLink test={props.first} url={`/${previousUrl}`} className={`pagination-previous ${buttonClass}`} text="Previous" />
      <NavLink test={props.last} url={`/${nextUrl}`} className={`pagination-next ${buttonClass}`} text="Next" />

      <ul className="pagination-list">
        {!props.first && <li><Link to="/" className={`pagination-link ${buttonClass}`}>1</Link></li>}
        {props.index > 3 && <li><span className="pagination-ellipsis">&hellip;</span></li>}

        {props.index - 1 >= 2 && <li><Link to={`/${props.index - 1}/`} className={`pagination-link ${buttonClass}`}>{props.index - 1}</Link></li>}
        <li><Link to={`/${props.index !== 1 ? props.index + '/' : ''}`} className="pagination-link button is-primary button is-rounded is-responsive-small">{props.index}</Link></li>
        {props.index < (props.pageCount -1) && <li><Link to={`/${props.index + 1}/`} className={`pagination-link ${buttonClass}`}>{props.index + 1}</Link></li>}

        {props.index < (props.pageCount - 2) && <li><span className="pagination-ellipsis">&hellip;</span></li>}
        {!props.last && <li><Link to={`/${props.pageCount}/`} className={`pagination-link ${buttonClass}`}>{props.pageCount}</Link></li>}
      </ul>
    </nav>
  )
}

const IndexPage = ({ pageContext, data: { images, assets, owners } }) => {
  const [hoveredAsset, setHoveredAsset] = useState();
  const numberOfDistinctOwners = [...new Set(owners.edges.map(edge => edge.node.owner))].length + 1 // + 1 here for me (Darkport)

  const customMeta = [{
    name: `twitter:image`,
    content: `https://www.mypixelplanet.com${icon}`,
  }];

  const goToRandomPlanet = () => navigate(`/${assets.edges[Math.floor(Math.random() * assets.edges.length)].node.id}/`);

  return (
    <Layout title="My Pixel Planet">
      <SEO title="My Pixel Planet" meta={customMeta} />

      <h4 className="subtitle is-font-pixel has-text-grey-lighter has-text-centered is-size-6 is-size-7-mobile mt-1">
        There are 59 happy owners of {owners.edges.length} planets. {101 - owners.edges.length} planets left up for grabs!
      </h4>

      <h2 className="title is-size-3 is-size-4-mobile has-text-white has-text-weight-bolder has-text-centered" style={{ margin: '50px 0' }}>A limited and unique generative art collection of animated pixel planets. Own a part of the digital universe, forever captured on the Ethereum blockchain.</h2>
      <h3 className="subtitle has-text-grey-lighter has-text-centered is-size-5">Have fun exploring the collection of AI-generated planets. Discover each planets unique characteristics — from its size, colour, and temperature to the number of orbiting satellites and its distinct sound. And just like real planets, some harbour lifeforms.</h3>
      <h3 className="subtitle has-text-grey-lighter has-text-centered is-size-5">Join the adventure. Own your own planet. #0 through #100 in 1/1 editions.</h3>

      <div style={{ margin: "0 auto" }} className="has-text-centered">
        <button onClick={() => goToRandomPlanet()} className="button mt-3 mx-2 is-responsive is-primary is-rounded has-text-weight-bolder"><RiEarthFill className="mr-3" /> Explore a Random Planet</button>
        <OutboundLink href="https://opensea.io/collection/my-pixel-planet" rel="noreferrer" target="_blank" className="button mt-3 mx-2 is-responsive is-primary is-outlined is-rounded has-text-weight-bolder"><FaEthereum className="mr-3" /> View the Collection on OpenSea</OutboundLink>
      </div>

      <div className="columns is-multiline mt-6">
        {
          pageContext.group.map((result) => {
            const asset = result.node
            const image = images.edges.filter(({ node }) => node.name === asset.id)[0].node.childImageSharp

            return (
              <div key={asset.id} className="column is-one-third" onMouseOver={() => setHoveredAsset(asset.id) } onFocus={() => setHoveredAsset(asset.id) }>
                <div className={`card ${asset.legendary && "legendary"} ${asset.rare && "rare"} ${asset.epic && "epic"}`}>
                  <div className="card-image">
                    <figure className="image" style={{ position: "relative" }}>
                      <Link to={`/${asset.id}/`}>
                        <img src={hoveredAsset === asset.id || asset.legendary ? `/${asset.id}.gif` : image.fluid.src} className="has-ratio" />
                      </Link>
                      <PlanetTags asset={asset} />
                    </figure>
                  </div>

                  <div className="card-content  has-text-centered">
                    <div className="media">
                      <div className="media-content">
                        <p className="title is-size-4 is-font-pixel"><Link to={`/${asset.id}/`}>{asset.name}</Link></p>
                      </div>
                    </div>

                    <div className="content">
                      <ReactMarkdown>
                        {asset.description}
                      </ReactMarkdown>
                    </div>

                    {asset.forSale && <OutboundLink href={asset.opensea} target="_blank" rel="noreferrer" className="button is-primary is-small is-outlined is-rounded has-text-weight-bolder"><FaEthereum className="mr-2" /> Buy on OpenSea for Ξ{asset.price}</OutboundLink> }
                    {!asset.forSale && <button className="button is-primary is-small is-outlined is-disabled is-rounded is-italic is-inverted" disabled>Currently not for sale</button> }
                  </div>

                  <footer className="card-footer">
                    <p className="card-footer-item" title={`${asset.name} has ${asset.lifeforms} lifeforms`}>
                      <span className="is-size-7 has-text-grey-light">
                        <RiAliensLine size="1rem" style={{ marginRight: 5 }} /> {asset.lifeforms}
                      </span>
                    </p>
                    <p className="card-footer-item" title={`${asset.name}'s temperature`}>
                      <span className="is-size-7 has-text-grey-light">
                        <RiTempColdLine size="1rem" style={{ marginRight: 5 }} /> {asset.temperature}
                      </span>
                    </p>
                  </footer>
                </div>
              </div>
            )
          })
        }
      </div>

      <Pager {... pageContext} />

    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    images: allFile(filter: { relativeDirectory: { eq: "planets" }}) {
      edges {
        node {
          name
          childImageSharp {
            fluid {
              ... GatsbyImageSharpFluid
            }
          }
        }
      }
    },
    assets: allAsset {
      edges {
        node {
          id
        }
      }
    },
    owners: allAsset(filter: { owner: { ne: "NullAddress" } }) {
      edges {
        node {
          owner
        }
      }
    } 
  }
`